import Vue from "vue";

/*TITLE*/
document.title = "Nueva Alcayna | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Nueva Alcayna";
Vue.prototype.$subtitle = "Cumple tu sueño";

/*INTRO*/
Vue.prototype.$promoter = "Nueva Alcayna";
Vue.prototype.$introSubtitle = "Cumple tu sueño";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-op4.-forest-cognac--20230118110125.jpg";
Vue.prototype.$image_kitchen = "cocina-forest-cognac-frentes-blanco-snow-encimera-krion-snow-white-copete_no--20230118110155.jpg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091759.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091759.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-op4.-forest-cognac--20230118110125.jpg",
  },
  {
    src: "salon-op3.-urban-caliza-nature--20230118110132.jpg",
  },
  {
    src: "salon-op2.-persa-silver--20230118110139.jpg",
  },
  {
    src: "salon-op1.-urban-natural-nature--20230118110146.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-forest-cognac-frentes-blanco-snow-encimera-krion-snow-white-copete_no--20230118110155.jpg",
  },
  {
    src: "cocina-opc.-3-persa-silver-frentes-etimoe-ice-encimera-krion-snow-white-copete--20230118110103.jpg",
  },
  {
    src: "cocina-opc.-1-urban-caliza-nature-frentes-etimoe-land-encimera-krion-snow-white-copete--20230118110137.jpg",
  },
  {
    src: "cocina-opc.-2-urban-natural-nature-frentes-etimoe-land-encimera-krion-snow-white-copete--20230118110143.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091759.jpeg",
  },
  {
    src: "1-bathroom1--20221124091759.jpeg",
  },
  {
    src: "2-bathroom1--20221124091759.jpeg",
  },
  {
    src: "3-bathroom1--20221124091759.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091759.jpeg",
  },
  {
    src: "1-bathroom2--20221124091759.jpeg",
  },
  {
    src: "2-bathroom2--20221124091759.jpeg",
  },
  {
    src: "3-bathroom2--20221124091759.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/NUEVA_ALCAYNA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/742982659";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=molina%20del%20segura",
    text: "Molina del Segura",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=ronda%20norte%20sur%20al%20lado%20del%20supercor%20",
    text: "Ronda Norte-Sur (al lado del Supercor)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:629550000/968715290",
    text: "629550000/968715290",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@nuevaalcayna.es",
    text: "info@nuevaalcayna.es",
  },
];
